@import "../../global.scss";

.intro {
  // height: 100vh;
  width: 100vw;
  background: linear-gradient(
    360deg,
    #d1ebee,
    #d3ecef,
    #d8eef1,
    #def0f4,
    #e5f3f6,
    #ecf6f9,
    #f0f8fa,
    #f2f9fb
  );
  overflow-x: clip;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  background-image: url("./hero/2-mobile.jpg");
  background-position: bottom;

  .container {
    // position: absolute;
    z-index: 1;
    background: linear-gradient(
      180deg,
      #f4f9fc,
      #eef5f9,
      #e9f1f5,
      #e3edf1,
      #dde9ee,
      #d8e6ea6b,
      #d2e2e600,
      #ccdee200
    );
  }
  h1 {
    color: var(--primary-dark);
    // text-shadow: 0px 15px 24px #1136453b;
  }
}
.intro h1 {
  font-size: 44px;
  animation: fadeInDown 0.5s ease both;
}
.intro .inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100vw;
  height: 91vh;
  min-height: 720px;
  // margin: 2.5rem 0;
}
.intro .hero {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  object-position: bottom;
}
.intro-text {
  width: 680px;
  text-align: center;
}
.intro-text p {
  padding: 0 1.75rem;
  font-size: 16px;
  line-height: 160%;
  margin-bottom: 32px;
  animation: fadeInDown 0.5s ease 0.2s both;
}
.intro button {
  position: relative;
  background: linear-gradient(
    125deg,
    rgba(22, 83, 108, 1) 0%,
    rgba(64, 124, 149, 1) 100%
  );
  cursor: pointer;
  animation: fadeInDown 0.5s ease-in 0.5s both;
}
.intro button::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -10px;
  left: 5%;
  height: 110%;
  width: 90%;
  opacity: 0.4;
  border-radius: 50px;

  /* Declaring our shadow color inherit from the parent (button) */
  background: inherit;

  /* Blurring the element for shadow effect */
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
  -o-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);

  /* Transition for the magic */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.intro button:hover::after {
  /* Changing blur effect */
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);

  /* And change the style properties */
  width: 100%;
  bottom: -5px;
  left: 0;
}
.intro button:hover:active::after {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}
@media screen and (min-width: 768px) {
  .intro {
    background-image: url("./hero/2-tablet.jpg");
  }
  .intro h1 {
    font-size: calc(1.25rem * 3.05125);
  }
  .intro .inner {
    padding-left: 10%;
  }
  .intro-text {
    text-align: left;
    padding-top: 20px;
  }
  .intro-text p {
    font-size: 20px;
    padding: 0;
    margin-bottom: 40px;
  }
  .intro .container {
    background: linear-gradient(
      180deg,
      #f4f9fc,
      #eef5f9,
      #e9f1f5,
      #e3edf180,
      #dde9ee0a,
      #d8e6ea00,
      #d2e2e600,
      #ccdee200
    );
  }
}
@media screen and (min-width: 1024px) {
  .intro {
    background-image: url("./hero/2.jpg");
  }
  .intro .inner {
    padding-left: 15%;

    transform: translateY(10%);
    // margin: 5rem 0 2.5rem 0;
  }
  .intro .container {
    background: linear-gradient(
      170deg,
      #f5fafd,
      #eff6f9,
      #e9f2f6c4,
      #e4eef270,
      #deeaee1c,
      #d8e6ea1a,
      #d2e2e600,
      #ccdee200
    );
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
