#works {
  padding: 32px 40px 0 40px;
}
#works .portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#works .portfolio h4 {
  margin: 0;
}
.portfolio .portfolio-tags {
  padding: 0;
  justify-content: center;
}
.portfolio .portfolio-tags li {
  margin: 5px;
  font-size: 12px;
  text-align: center;
}
.portfolio .project-tags li {
  margin-right: 10px;
}
.portfolio .portfolio-tags li button {
  padding: 8px 8px;
}
.portfolio .portfolio-tags,
.portfolio .project-tags {
  display: flex;
  flex-wrap: wrap;
}
.portfolio .project-tags {
  padding: 0;
}
.portfolio .grid-container {
  display: block;
}
.portfolio .grid-container .item {
  color: white;
  background: rgb(0, 0, 0);
  width: 350px;
  height: 292px;

  border-radius: 16px;
  box-shadow: var(--drop-shadow-1);
  position: relative;
  cursor: pointer;
  margin-bottom: 72px;
  text-align: center;

  &:active {
    box-shadow: var(--drop-shadow-4);
  }
  h5 {
    margin: 0px;
  }
  small {
    color: var(--text-color);
  }
}
.portfolio .grid-container .item h4 {
  color: white;
}
.portfolio .grid-container .item img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}
.portfolio .grid-container .item .info {
  padding: 40px;
  position: relative;
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#mug {
  width: 400px;
  position: absolute;
  bottom: -200px;
  z-index: -1;
  left: -260px;
}
@media screen and (min-width: 768px) {
  .portfolio .portfolio-tags li {
    flex-basis: unset;
  }
  .portfolio .grid-container {
    display: grid;
    padding: 32px 0;
    grid-template-columns: auto auto;
    grid-column-gap: 24px;
    grid-row-gap: 80px;
  }
  #works {
    padding: 32px 15% 64px 15%;
  }
  .portfolio .grid-container .item {
    margin-bottom: 16px;
  }

  .portfolio .grid-container .item h5 {
    margin: 8px;
  }
}
@media screen and (min-width: 1024px) {
  .portfolio .portfolio-tags li button {
    padding: 8px 16px;
  }
  #works {
    padding: 32px 15% 160px 15%;
  }
  .portfolio.portfolio-tags {
    justify-content: flex-start;
  }
  .portfolio .portfolio-tags li {
    margin: 0 10px;
    flex-basis: unset;
    font-size: inherit;
  }
  .portfolio .grid-container .item {
    width: 500px;
    height: 417px;
    margin: 0;
  }
  .portfolio .grid-container {
    display: grid;
    padding: 32px 0;
    grid-template-columns: auto auto;
    grid-column-gap: 54px;
    grid-row-gap: 96px;
  }
}
