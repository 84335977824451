#about {
  padding-top: 120px;
  padding-bottom: 72px;
}
#about .portrait {
  width: 535px;
  border-radius: 16px;
  box-shadow: var(--drop-shadow-3);
}
#about .inner {
  display: block;
  position: relative;
}
#about .inner .portrait {
  width: 320px;
  text-align: center;
  margin: auto;
  display: block;
}
#about .inner h2 {
  text-align: center;
}
#about .inner button {
  margin: auto;
  display: block;
}
#about .inner .text {
  background: #f4f9fcb5;
  margin: 0 16px;
}
#about #resume {
  position: absolute;
  z-index: -1;
  bottom: -200px;
  width: 648px;
  right: -240px;
}
@media screen and (min-width: 768px) {
  #about .inner .text {
    margin-left: 2rem;
  }
  #about .inner {
    width: 70%;
    padding: 0 15%;
    display: block;
  }
  #about .inner .portrait {
    width: 400px;
    margin: auto;
    text-align: center;
    margin: auto;
    display: block;
  }
  #about .inner h2 {
    text-align: center;
  }
  #about .inner button {
    margin: auto;
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  #about {
    padding: 0 0 200px 0;
  }
  #about .inner {
    display: flex;
    width: 70%;
    justify-content: center;
    align-items: center;
    padding: 0 15%;

    > * {
      flex: 1;
    }
  }
}
