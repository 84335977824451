nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 8vh;
  background: none;
  padding: 1vh 0;
  width: 100vw;
}
nav .logo {
  width: 50px;
  margin-left: 20px;
}
nav ul {
  display: flex;
  justify-content: space-around;
  width: 20%;
  padding-right: 2rem;
}

.burger {
  display: none;
}
.burger div {
  width: 26px;
  height: 3px;
  background-color: var(--primary-dark);
  margin: 5px;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}
@media screen and (max-width: 1024px) {
  nav ul {
    width: 40%;
  }
}
@media screen and (orientation: portrait) {
  body {
    overflow-x: hidden;
  }
  nav {
    // border-bottom: 3px solid var(--light);
    // background: var(--light);
    background: rgb(244 249 252 / 34%);
    width: 100vw;
    overflow: hidden;
  }
  nav ul {
    display: block;
    position: absolute;
    left: 0px;
    height: 92vh;
    top: 8.5vh;
    width: 100vw;
    text-align: center;
    padding-top: 30%;
    padding-left: 0px;
    background: var(--light);
    z-index: 1000;
    transition: visibility 0s linear 300ms, opacity 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
  }
  nav ul li {
    // opacity: 0;
    font-family: Halant;
    padding: var(--space-md);
  }
  nav ul li a {
    font-size: 3rem;
    font-weight: 600;
    color: var(--primary-dark);
  }
  .burger {
    display: block;
    cursor: pointer;

    margin-right: 20px;
  }
}
.active {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.toggle .line1 {
  transform: rotate(-405deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
