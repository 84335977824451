#contact {
  background-color: lightblue;
  height: 90vh;
}
#contact button {
  margin-top: 20px;
}
#contact .links span {
  padding-right: 20px;
}
#contact .links small {
  padding-top: 24px;
}
#contact .links {
  bottom: 0;
  position: absolute;
  width: 100%;
  margin-bottom: 24px;
}
#contact .bg {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  object-position: bottom;
}
#contact .container {
  padding-top: 32px;
  position: absolute;
  padding: 0 8%;
  width: calc(100% - (8 * 2%));
  height: 100vh;
}
#contact input,
textarea {
  width: 100%;
  padding: 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #daebee;
  border-radius: 8px;
  box-sizing: border-box;
  resize: vertical;
  // margin-bottom: 32px;
  // background: #e4e7eb;
}
#contact textarea:focus,
#contact input:focus {
  outline: 1px solid #056e9a;
  box-shadow: 0px 0px 0px 3px #247ba033;
  background: white;
}
@media screen and (min-width: 768px) {
  #contact .container {
    padding: 0 8%;
    width: calc(100% - (8 * 2%));
  }
}
@media screen and (min-width: 1024px) {
  #contact .container {
    padding-left: 15%;
    width: 50%;
  }
}
