@import url("https://fonts.googleapis.com/css2?family=Halant:wght@500&family=Inter:wght@300;400;500;600;700&display=swap");

/* CSS Variables*/
:root {
  --text-color: #464c4e;
  --dark: #0a2029;
  --light: #f4f9fc;
  --primary: #056e9a;
  --primary-dark: #1d5c77;
  --primary-light: #d3e5ec;
  --subtle: #90999e;

  --space-unit: 1rem;
  --space-xxs: calc(0.5 * var(--space-unit));
  --space-xs: calc(1 * var(--space-unit));
  --space-sm: calc(1.5 * var(--space-unit));
  --space-md: calc(2 * var(--space-unit));
  --space-lg: calc(2.5 * var(--space-unit));
  --space-xl: calc(3.5 * var(--space-unit));
  --space-xxl: calc(4.5 * var(--space-unit));

  --padding-sm: var(--space-xxs);
  --padding-md: var(--space-xs);
  --padding-lg: calc(1.25 * var(--space-unit));

  --drop-shadow-1: 0px 4px 24px rgba(0, 0, 0, 0.2);
  --drop-shadow-2: 0px 4px 24px rgba(0, 0, 0, 0.15);
  --drop-shadow-3: 0px 4px 24px rgba(10, 32, 41, 0.1);
  --drop-shadow-4: 0px 4px 24px rgba(0, 0, 0, 0.05);
}
body {
  /*styleName: Body@16;*/
  font-family: Inter;
  font-size: 1rem;
  font-weight: 300;
  line-height: 160%;
  margin: 0;
  color: var(--text-color);
  overflow-x: hidden;
  background: var(--light);
}

/*FONTS*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Halant;
  line-height: 120%;
  color: var(--dark);
  font-weight: 500;
}
h1 {
  /*styleName: Heading 1@60;*/
  font-family: Halant;
  // font-size: 60px;
  font-size: calc(1.25rem * 3.05125);
}
h2 {
  /*styleName: Heading 2@48;*/
  font-size: calc(1.25rem * 2.441);
}
h3 {
  /*styleName: Heading 3@40;*/
  font-size: calc(1.25rem * 1.953125);
}
h4 {
  /*styleName: Heading 4@32;*/
  font-size: calc(1.25rem * 1.5625);
}
h5 {
  /*styleName: Heading 5@24;*/
  font-size: calc(1.25rem * 1.25);
}
h6 {
  /*styleName: Heading 6@20;*/
  font-size: 1.25rem;
}
a {
  color: var(--primary);
  text-decoration: none;
}
ul {
  list-style-type: none;
}

/*Components*/
button {
  border-radius: var(--space-xxs);
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  cursor: pointer;
}

button.primary {
  padding: var(--space-xs) calc(1.25 * var(--space-unit));
  background: var(--primary-dark);
  color: white;
  border: 0px;
}
button.secondary {
  padding: var(--padding-md) calc(1.25 * var(--space-unit));
  color: var(--primary-dark);
  background: var(--primary-light);
}
button.tertiary {
  padding: var(--padding-md) calc(1.25 * var(--space-unit));
  color: var(--primary-dark);
  border: solid 2px var(--primary-light);
}
button.chip-primary {
  background: var(--primary-light);
  color: var(--primary-dark);
  padding: 8px 16px;
  border: 0;
}
button.chip-secondary {
  background: var(--primary-light);
  color: var(--text-color);
  padding: 8px 16px;
  border: 0;
}
button.chip-tertiary {
  border: solid 3px var(--primary-light);
  color: var(--text-color);
  padding: 8px 16px;
  background: none;
}
button.chip-primary.active {
  color: var(--primary-light);
  background: var(--primary-dark);
}
