.art .grid-container {
  justify-content: center;
  padding: 48px;
  display: block;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 24px;
}
.art .grid-container img {
  object-fit: contain;
  width: 33%;
}

.project-body {
  margin: 0 24px;
}
.project-body img {
  max-width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}
.project-body ul li svg {
  margin-right: 16px;
}
.art #animation {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@media screen and (min-width: 768px) {
  .art .grid-container {
    display: flex;
  }
  .project-body {
    margin: 0 48px;
  }
}
@media screen and (min-width: 1024px) {
  .art .grid-container {
    display: flex;
  }
  .project-body {
    margin: 0 20vw;
  }
}
